import React, { useState, useRef, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import { Title } from '../../components'

import './Widget.css'

export default function Widget({title, children}){

  const [opened, toggle] = useState(false)
  const content = useRef(null)
  const button = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (!button.current || button.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  return(
    <div className={`widget ${opened ? "opened" : ""}`}>
      <div className="widget-header">
        <Title tag="h3">{title}</Title>
        <div className="toggle" onClick={() => toggle(!opened)} ref={button}>
          {opened ?
            <FontAwesome name="minus-circle" />
          :
            <FontAwesome name="plus-circle" />
          }
        </div>
      </div>
      <div className="widget-content no-padding" style={opened ? {maxHeight: content.current.scrollHeight} : {} } ref={content}>
        {children}
      </div>
    </div>
  )

}
