import React from 'react'
import { Link } from 'react-router-dom'

import './Logo.css'
import logo from './logo.png'

export default function Logo(){

  return(
    <Link to="/">
      <div id="logo">
        <img src={logo} title="Vector Cabinet" alt="Vector Cabinet" />
      </div>
    </Link>
  )

}
