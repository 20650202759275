import React from 'react'
import FontAwesome from 'react-fontawesome'

import './NotFound.css'

export default function NotFound() {
  return(
    <div id="not-found" className="container">
      <div id="not-found-wrapper">
        <FontAwesome name="exclamation-triangle"/>
        <p className="error-text">The requested page not found or already moved to another location.</p>
      </div>
    </div>
  )
}
