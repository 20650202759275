import React from 'react'
import parse from 'html-react-parser'

import { Title } from '../../components'

import products from '../../data/promotions.json'

import './Promotions.css'

export default function Promotions(){

  return(
    <div id="promotions" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <div className="content">
            <div className="page-title">
              <Title tag="h1">Promotions</Title>
            </div>
            <div className="products">
              {products.map(({ slug, description, name, tags, promotionalPrice, brochure, media: { main } }, key) =>
                <div key={key} className="product">
                  <div className="info">
                    <Title tag="h2"><span>{name}</span>{tags && tags.includes("NEW") && <span className="badge">NEW</span>}</Title>
                    <div className="price-wrapper">
                      <div className="price">{promotionalPrice}</div>
                      <div className="label">LIMITED STOCK THE OFFER IS SUBJECT TO AVAILABILTY</div>
                    </div>
                    <div className="desc">{parse(description)}</div>
                    <a target="_blank" rel="noopener noreferrer" className="button main" href={`${process.env.PUBLIC_URL}/promotions/${slug}/${brochure}`}>Download promotionional brochure </a>
                  </div>
                  <div className="image">
                    <img src={`${process.env.PUBLIC_URL}/promotions/${slug}/${main}`} title={name} alt={name} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
