import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import { PrivateRoute } from './components'
import { NotFound, Home, AboutUs, Machinery, Product, Products, References, Promotions  } from './containers'

export default function Routes() {

  return(
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/about-us" component={AboutUs}/>
      <Route exact path="/machinery" component={Machinery}/>
      <Route exact path="/references" component={References}/>
      <Route exact path="/products" component={Products}/>
      <Route exact path="/products/:slug" component={Product}/>
      <Route exact path="/promotions" component={Promotions}/>
      <Route component={NotFound}/>
    </Switch>
  )
}
