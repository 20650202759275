import React from 'react'
import { NavLink } from 'react-router-dom'

import { Widget } from '../../components'

import products from '../../data/products.json'
import endProducts from '../../data/end-of-series.json'

export default function ProductsSidebar(){

  return(
    <div className="sidebar">
      <div className="widgets sticky">
        <Widget title="Products">
          <nav className="menu">
            {products.map(({ slug, name, tags }, key) =>
              <NavLink key={key} to={`/products/${slug}`}>
                <span>{name}</span>
                {tags && tags.includes("NEW") && <span className="badge">NEW</span>}
              </NavLink>
            )}
          </nav>
        </Widget>
        <Widget title="End-of-series products">
          <nav className="menu">
            {endProducts.map(({ slug, name, tags }, key) =>
              <NavLink key={key} to={`/products/${slug}`}>
                <span>{name}</span>
                {tags && tags.includes("NEW") && <span className="badge">NEW</span>}
              </NavLink>
            )}
          </nav>
        </Widget>
      </div>
    </div>
  )

}
