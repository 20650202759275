import React from 'react'

import './Navigation.css'

export default function Navigation({id, children, className}) {

  return(
    <nav id={id} className={`navigation ${className ? className : ""}`}>
      {children}
    </nav>
  )

}
