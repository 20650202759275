import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import ReactGA from 'react-ga'

// import { ModalContext, SlideInContext } from '../../contexts'
import history from '../../history'

export default function Wrapper({children}) {

  // const { closeMenu } = useContext(SlideInContext)
  // const { closeModal } = useContext(ModalContext)

  useEffect(() => {
    history.listen((location) => {
      // closeModal()
      // closeMenu()
    })
// eslint-disable-next-line
  }, [])

  return(
    <Route render={({ location }) => {
      if (process.env.NODE_ENV === 'production') {
        ReactGA.set({ page: location.pathname + location.search })
        ReactGA.pageview(location.pathname + location.search)
      }
      return children
    }} />
  )
}
