import React from 'react'
import Slider from 'react-slick'

import slider from '../../data/references.json'

import './ReferencesSlider.css'

const settings = {
  dots: true,
  arrows: false,
  autoplay: true,
  infinite: true,
  speed: 200,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnHover:false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default function ReferencesSlider(){

  return(
    <div className="row wrapped">
      <Slider className="references-slider" {...settings}>
        {slider.map(({url, name}, key) =>
          <div key={key}>
            <div className="references-slider-item">
              <div className="image">
                <img src={`${process.env.PUBLIC_URL}/references/${url}`} title={name} alt={name} />
              </div>
            </div>
          </div>
        )}
      </Slider>
    </div>
  )

}
