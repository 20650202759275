import React, { useState, useRef, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome'

import { Navigation, NavItem, NavButton } from '../../components'
import { Logo } from '../../views'

import './Header.css'

function Header({history: {location: {pathname}}}){

  const [opened, toggle] = useState(false)
  const navigation = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      console.log("navigation.current", navigation.current);
      if (!navigation.current || (navigation.current && navigation.current.contains(e.target))) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  function scrollTo(to, offset = 0){
    toggle()
    window.scrollTo({top: document.getElementById(to).offsetTop - offset, left: 0, behavior: 'smooth' })
  }

  return(
    <div id="header">
      <Logo />
      <div className="navigation-wrapper" ref={navigation}>
        <div className="navigation-toggle" >
          <FontAwesome name="bars" onClick={() => toggle(!opened)} />
        </div>
        <Navigation id="main-menu" className={`responsive ${opened ? "opened" : ""}`} >
          <NavItem exact target="/" onClick={() => toggle(false)}>Home</NavItem>
          <NavItem target="/products" onClick={() => toggle(false)}>Products</NavItem>
          <NavItem target="/promotions" onClick={() => toggle(false)}>Promotions</NavItem>
          <NavItem target="/references" onClick={() => toggle(false)}>References</NavItem>
          <NavItem target="/machinery" onClick={() => toggle(false)}>Machinery</NavItem>
          <NavItem target="/about-us" onClick={() => toggle(false)}>About us</NavItem>
          <NavButton onClick={() => scrollTo("footer-bar")}>Contact</NavButton>
        </Navigation>
      </div>
    </div>
  )

}

export default withRouter(Header)
