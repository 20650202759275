import React from 'react'

import { Title } from '../../components'

import './Jumbo.css'

export default function Jumbo({id, title, children}){

  return(
    <div id={id} className="jumbo">
      <div className="row wrapped">
        {title && <Title tag="h2">{title}</Title>}
        {children &&
          <div className="sub">
            {children}
          </div>
        }
      </div>
    </div>
  )

}
