import React from 'react'

import { Page } from '../../views'

export default function AboutUs(){

  return(
    <div id="about-us" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <div className="content">
            <Page title="About us">
              <p>The Vector Cabinet Ltd, as the successor of Szucs Bt., deals with design and manufacturing of metallic and wooden spare parts, as well as of covers of different electronic devices since 1991. This activity particularly includes the design and manufacturing of cabinets for video games, slot machines, frame and cover of roulette tables, boxes for wending machines and Internet terminals, internal spare parts for the above devices, as well as of snooker tables.</p>
              <p>During the last couple of years we have created a slot machine cabinet family of our development which includes a wide variety starting from single monitor machines and ending with double monitor cabinets capable to house 22' LCDs. The main feature of our system is that we are able to flexibly modify the internal structure of our cabinets according to the special demand of our customers, therefore our partners have the opportunity to purchase products tailor-made to their requirements. It is guaranteed by our special manufacturing system, by the computerized design, as well as by our CNC-operated machinery that we are constantly able to supply our business partners with the highest quality within shortest deadline. Having these benefits at hand nowadays we supply several European countries and through our partners our products reach the overseas markets, as well.</p>
              <p>Our main objective is to satisfy our customers' needs with a best possible complex service. In this spirit on the basis of special individual requests we design special wooden and metallic frames with the help of our 3D CAD programs. In our carpenter factory we provide for CNC manufacturing, vacuum-foiling and assembling of different wooden accessories and furnitures. Our factory for metallic works undertakes manufacturing of different metallic spare parts in small or large scales with the help of high-tech laser-cut and bending machines.</p>
            </Page>
          </div>
        </div>
      </div>
    </div>
  )

}
