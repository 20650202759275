import React from 'react'
import FontAwesome from 'react-fontawesome'

import './ContactCard.css'

export default function ContactCard(){

  return(
    <div className="contact-card">
      <div className="contact-card-item">
        <div className="icon">
          <FontAwesome name="id-card" />
        </div>
        <div className="label">
          <span className="info">Business name</span>
          <span>Gamingmatic Kft.</span>
        </div>
      </div>
      <div className="contact-card-item">
        <div className="icon">
          <i className="fas fa-map-marked"></i>
        </div>
        <div className="label">
          <span className="info">Address</span>
          <span>Airport Ipari Park (Külterület 0323/5 hrsz.)<br></br>2316 Tököl, Hungary</span>
        </div>
      </div>
      <div className="contact-card-item">
        <div className="icon">
          <i className="fa fa-envelope"></i>
        </div>
        <div className="label">
          <span className="info">Email</span>
          <span><a href="mailto:gabor.gamingmatic@gmail.com">gabor.gamingmatic@gmail.com</a></span>
        </div>
      </div>
      <div className="contact-card-item">
        <div className="icon">
          <i className="fas fa-phone"></i>
        </div>
        <div className="label">
          <span className="info">Telephone</span>
          <span> +36 30 289 0600</span>
        </div>
      </div>
    </div>
  )

}
