import React, { useState } from 'react'
import Modal from 'react-modal'

import { Title, CloseButton, Button } from '../../components'

import './PromotionsModal.css'
import gamingmatic from './gm+vector.jpg'

const style = {
  content : {
    left                  : '50%',
    top                   : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    display               : 'inline-block',
    margin                : '0 auto',
    padding               : '0',
    border                : 'none',
    borderRadius          : '5px',
    transform             : 'translate(-50%, -50%)'
  }
}

export default function PromotionsModal(){

  const [isOpened, toggle] = useState(true)

  function closeModal(){
    toggle(false)
  }

  return(
    <Modal isOpen={isOpened} style={style} onRequestClose={() => closeModal()} appElement={document.getElementById('root')}>
      <div id="promotions-modal" className="modal">
        <CloseButton onClick={() =>  closeModal()} />
        <div className="modal-info">
          <div className="modal-header">
            <Title tag="h2">Important News!</Title>
          </div>
          <div className="header-image">
          </div>
          <div className="modal-content">
            <div id="gamingmatic">
              <img src={gamingmatic} title="Gamingmatic, official successor of Vector Cabinet" alt="Gamingmatic, official successor of Vector Cabinet" />
            </div>
            <h3>Dear former Vector Cabinet Customers and Business Partners,</h3>
            <br></br>
            <p><strong>We proudly announce that from now on Gamingmatic Ltd. is the official successor of Vector Cabinet.</strong></p>
            <br></br>
            <p>Our activity particularly includes the design and manufacturing of cabinets for betting terminals, and slot machines, just like Vector Cabinet- but the main difference is that we are capable of designing and assembling the whole machine, all-in-one. Of course, we can provide the manufacturing of all product types that Vector Cabinet used to build to the customers.</p>
          </div>
          <div className="modal-footer">
            <Button type="main" className="full" onClick={() => closeModal()}>Ok, continue to the site</Button>
          </div>
        </div>
      </div>
    </Modal>
  )

}