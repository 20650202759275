import React from 'react'
import Slider from 'react-slick'
import { NavLink } from 'react-router-dom'

import { Title, Button } from '../../components'

import slider from '../../data/slider.json'

import './ProductSlider.css'

const settings = {
  dots: true,
  arrows: false,
  autoplay: true,
  infinite: true,
  height: 300,
  speed: 300,
  initialSlide: 0,
  pauseOnHover:false
}

export default function ProductSlider(){

  return(
    <div className="row">
      <Slider className="product-slider" {...settings}>
        {slider.map(({title, image, "image-title": imageTitle, text, slug, "product-slug": productSlug}, key) =>
          <div key={key}>
            <div className="product-slider-item">
              <div className="parallelogram"></div>
              <div className="parallelogram2"></div>
              <div className="image">
                <img src={`${process.env.PUBLIC_URL}/slideshow/${image}`} title={imageTitle} alt={imageTitle} />
              </div>
              <div className="info">
                <Title tag="h2">{title}</Title>
                <div className="desc">{text}</div>
                <NavLink to={`/products/${productSlug}`}><Button type="main">See more</Button></NavLink>
              </div>
            </div>
          </div>
        )}
      </Slider>
    </div>
  )

}
