import React from 'react'

import { Title } from '../../components'
import { Logo, ContactCard } from '../../views'

import './Footer.css'

export default function Footer(){

  return(
    <div id="footer-wrapper">
      <div id="footer-bar">
        <div className="row wrapped">
          <div className="boxes">
            <div className="box double">
              <Title tag="h3">Where you find us?</Title>
              <iframe title="map" frameBorder="0" className="map" seamless="seamless" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10814.41575334602!2d18.9952519!3d47.3416058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x830b81e8e690acb8!2sGamingmatic%20Kft.!5e0!3m2!1shu!2shu!4v1658911810595!5m2!1shu!2shu"></iframe>
            </div>
            <div className="box">
              <Title tag="h3">Contact us</Title>
              <ContactCard />
            </div>
          </div>
        </div>
      </div>
      <div className="row wrapped">
        <div id="footer">
          <Logo />
          <div id="copyright">ⓒ All rights reserved.</div>
        </div>
      </div>
    </div>
  )

}
