import React from 'react'

import { Title, Wysiwyg } from '../../components'

import './Page.css'

export default function Page({title, children}){

  return(
    <div id="page">
      {title && <Title tag="h1">{title}</Title>}
      <Wysiwyg>
        {children}
      </Wysiwyg>
    </div>
  )

}
