import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { Title } from '../../components'

import references from '../../data/references.json'

import './References.css'

export default function References(){

  const [galleryOpened, toggleGallery] = useState(false)
  const [photoIndex, setIndex] = useState(0)
  const galleryImages = references.map((img, key) => `${process.env.PUBLIC_URL}/references/${img.url}`)

  function showImage(index){
    setIndex(index)
    toggleGallery(true)
  }

  return(
    <div id="products" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <div className="content">
            <div className="page-title">
              <Title tag="h1">Our references</Title>
            </div>
            <div id="product-gallery">
              <div className="images">
                {references.map((image, key) =>
                  <div key={key} className="image" onClick={() => showImage(key)}>
                    <img src={`${process.env.PUBLIC_URL}/references/${image.url}`} title={image.name} alt={image.name} />
                  </div>
                )}
              </div>
              {galleryOpened &&
                <Lightbox
                  mainSrc={galleryImages[photoIndex]}
                  nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
                  prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
                  onCloseRequest={() => toggleGallery(false)}
                  onMovePrevRequest={() => setIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)}
                  onMoveNextRequest={() => setIndex((photoIndex + 1) % galleryImages.length)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
