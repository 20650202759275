import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import parse from 'html-react-parser'
import FontAwesome from 'react-fontawesome'

import { Title } from '../../components'
import { ProductsSidebar } from '../../views'

import products from '../../data/products.json'
import endProducts from '../../data/end-of-series.json'

import './Product.css'

export default function Product({match}){

  let path = "products"
  let product = products.find((prod) => prod.slug === match.params.slug)

  if (!product){
    product = endProducts.find((prod) => prod.slug === match.params.slug)
    path = "end-of-series"
  }

  const [galleryOpened, toggleGallery] = useState(false)
  const [photoIndex, setIndex] = useState(0)

  if (!product) return(
    <div id="products" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <div id="not-found-wrapper">
            <FontAwesome name="exclamation-triangle"/>
            <p className="error-text">The requested page not found or already moved to another location.</p>
          </div>
        </div>
      </div>
    </div>
  )

  const { slug, description, tags, name, media: { main, gallery } } = product
  const galleryImages = gallery.map((img, key) => `${process.env.PUBLIC_URL}/products/${slug}/${img.url}`)

  function showImage(index){
    setIndex(index)
    toggleGallery(true)
  }

  return(
    <div id="product" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <ProductsSidebar />
          <div className="content">
            <div className="page-title">
              <Title tag="h1"><span>{product.name}</span>{tags && tags.includes("NEW") && <span className="badge">NEW</span>}</Title>
            </div>
            <div className="product">
              <div className="info">
                <div className="desc">{parse(description)}</div>
                <a href={`${process.env.PUBLIC_URL}/${path}/${slug}/${slug}.pdf`} target="_blank" rel="noopener noreferrer" className="button main" alt="Download printable brochure">Download printable brochure</a>
              </div>
              <div className="image">
                <img src={`${process.env.PUBLIC_URL}/${path}/${slug}/${main}`} title={name} alt={name} />
              </div>
            </div>
            <div id="product-gallery">
              <Title tag="h2">Gallery</Title>
              <div className="images">
                {gallery.map((image, key) =>
                  <div key={key} className="image" onClick={() => showImage(key)}>
                    <img src={`${process.env.PUBLIC_URL}/${path}/${slug}/${image.url}`} title={image.name} alt={image.name} />
                  </div>
                )}
              </div>
              {galleryOpened &&
                <Lightbox
                  mainSrc={galleryImages[photoIndex]}
                  nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
                  prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
                  onCloseRequest={() => toggleGallery(false)}
                  onMovePrevRequest={() => setIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)}
                  onMoveNextRequest={() => setIndex((photoIndex + 1) % galleryImages.length)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
