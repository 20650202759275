import React from 'react'

import { Jumbo } from '../../components'
import { ProductSlider, ReferencesSlider } from '../../views'

import './Home.css'

export default function Home(){

  return(
    <div id="home" className="container">
      <ProductSlider />
      <Jumbo id="home-news" title="Professional slot and gaming machine cabinet manufacturer">
      </Jumbo>
      <Jumbo id="home-references" title="Our references">
          <ReferencesSlider />
      </Jumbo>
    </div>
  )

}
