import React from 'react'
import { Router, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import history from './history'
import Routes from './routes'

import { ScrollToTop, Wrapper } from './components'
import { Header, Footer, PromotionsModal } from './views'

function AppRouter() {

  return (
    <Router history={history}>
      <ScrollToTop>
        <Wrapper>
          <div id="app">
            <div id="main">
              <Route><Header /></Route>
              <Routes />
              <Route><Footer /></Route>
            </div>
            <ToastContainer />
            <PromotionsModal />
          </div>
        </Wrapper>
      </ScrollToTop>
    </Router>
  )
}

export default AppRouter
