import React from 'react'

import { Page } from '../../views'

import './Machinery.css'

export default function Machinery(){

  return(
    <div id="machinery" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <div className="content">
            <Page title="Machinery">
              <div className="text float">
                <p>Thanks to our wide range of cutting-edge machinery and professional equipment we can guarantee the best price and the highest quality for our customers. </p>
                <p>Our machinery and workflow includes, but not limited to the following items:</p>
                <ul>
                  <li>CAD/CAM(computerized system supported design and manufacturing)</li>
                  <li>CNC laser cut</li>
                  <li>Robot welding</li>
                  <li>CNC punching machine</li>
                  <li>CNC bending machine</li>
                  <li>AWI welding machine</li>
                  <li>CNC wood processing machinery (miller/driller machines)</li>
                </ul>
                <p>Finishing possibilities:</p>
                <ul>
                  <li>Powder coating, sintering</li>
                  <li>Chroming</li>
                  <li>Coating with zinc</li>
                </ul>
              </div>
              <div className="image float">
                <img src={`${process.env.PUBLIC_URL}/machinery/amada-lcg3015.jpg`} title="Machinery - VectorCabinet" alt="Machinery - VectorCabinet"/>
              </div>
            </Page>
          </div>
        </div>
      </div>
    </div>
  )

}
