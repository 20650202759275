import React from 'react'
import FontAwesome from 'react-fontawesome'

export default function Title({id, tag = "h1", icon, children}) {

  const Tag = tag

  return(
    <div id={id} className={["title"].join(" ")}>
      <Tag>
        {icon && <FontAwesome name={icon} />}
        {children}
      </Tag>
    </div>
  )
}
