import React from 'react'

import './Wysiwyg.css'

export default function Wysiwyg({children}){

  return(
    <div className="wysiwyg">
      {children}
    </div>
  )

}
