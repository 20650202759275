import React from 'react'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'

import { Title, Button } from '../../components'
import { ProductsSidebar } from '../../views'

import products from '../../data/products.json'
import endProducts from '../../data/end-of-series.json'

import './Products.css'

export default function Products(){

  return(
    <div id="products" className="container">
      <div className="row wrapped">
        <div className="content-wrapper">
          <ProductsSidebar />
          <div className="content">
            <div className="page-title">
              <Title tag="h1">Products</Title>
            </div>
            <div className="products">
              {products.map(({ slug, description, name, tags, media: { main } }, key) =>
                <div key={key} className="product">
                  <div className="info">
                    <Title tag="h2"><span>{name}</span>{tags && tags.includes("NEW") && <span className="badge">NEW</span>}</Title>
                    <div className="desc">{parse(description)}</div>
                    <NavLink to={`/products/${slug}`}>
                      <Button type="main">See gallery and more info</Button>
                    </NavLink>
                  </div>
                  <div className="image">
                    <img src={`${process.env.PUBLIC_URL}/products/${slug}/${main}`} title={name} alt={name} />
                  </div>
                </div>
              )}
            </div>
            <div className="page-title">
              <Title tag="h1">End-of-series products</Title>
            </div>
            <div className="products">
              {endProducts.map(({ slug, description, name, tags, media: { main } }, key) =>
                <div key={key} className="product">
                  <div className="info">
                    <Title tag="h2"><span>{name}</span>{tags && tags.includes("NEW") && <span className="badge">NEW</span>}</Title>
                    <div className="desc">{parse(description)}</div>
                    <NavLink to={`/products/${slug}`}>
                      <Button type="main">See gallery and more info</Button>
                    </NavLink>
                  </div>
                  <div className="image">
                    <img src={`${process.env.PUBLIC_URL}/end-of-series/${slug}/${main}`} title={name} alt={name} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
